<template>
    <div class="post-tag disable-select" v-dragscroll>
        <v-chip v-if="Number(post.offer_type) === $variables.v.offer_types.giveaway && Number(post.points) >= $variables.v.post_trending_giveaway_requirement">
            {{$t('post.popular')}}
        </v-chip>
        <v-chip v-if="Number(post.offer_type) === $variables.v.offer_types.deal && Number(post.points) >= $variables.v.post_hot_deals_requirement">
            {{$t('post.popular')}}
        </v-chip>
        <v-chip v-if="post.itemtypeID">{{$t($variables.v.itemtypes.find(x => x.id === Number(post.itemtypeID)).text)}}</v-chip>
        <v-chip v-if="post.serviceID">{{$t($variables.v.services.find(x => x.id === Number(post.serviceID)).text)}}</v-chip>
        <v-chip v-if="post.vpn_required === '1'">{{$t('post.vpn')}}</v-chip>
        <v-chip v-if="post.key_giveaway === '1'">{{$t('post.keys')}}</v-chip>
        <v-chip v-if="post.base_game_not_free === '1'">{{$t('post.paid-base-game')}}</v-chip>
        <v-chip v-if="remaining_days <= 0 && remaining_days > -1">{{$t('post.last-day')}}</v-chip>
    </div>
</template>

<script>
    import {dragscroll} from 'vue-dragscroll'


    export default {
        props     : ['post'],
        data() {
            return {
                remaining_days: 0,
            }
        },
        beforeMount() {
            this.remaining_days = this.calc_remaining_days(this.post.expiration_date);
        },
        methods   : {
            calc_remaining_days(date) {
                let eventdate  = this.moment(date);
                let todaysdate = this.moment();
                return eventdate.diff(todaysdate, 'days');
            },
        },
        directives: {
            'dragscroll': dragscroll
        },

    }
</script>
